@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html, body, #root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

.MuiInputBase-multiline, .MuiInputBase-multiline textarea {
  height: 100%;
}

.animate-pulse-bounce {
  animation: pulse-bounce 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-delay-100 {
  animation-delay: 250ms;
}

.animate-delay-200 {
  animation-delay: 500ms;
}

@keyframes pulse-bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(2px);
  }
  75% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-hourglass-spin {
  animation: hourglass-spin 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes hourglass-spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@layer utilities {
  .scrollbar-hidden::-webkit-scrollbar {
    width: 0px;
  }
}
